import React,{useContext} from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import {ItemListContext} from './ItemList';

const ServerList = ({data}) => {

    const [selectedItems, setSelectedItems] = useContext(ItemListContext);

    const headerSortingStyle = { backgroundColor: '#c8e6c9' };

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        hideSelectColumn: true,
        onSelect:(row, isSelect, rowIndex, e) => {
            console.log(row);
        }
    };

    const columns = [{
        dataField: 'provider',
        text: 'Provider',
        sort: true,
        headerSortingStyle,
        }, {
        dataField: 'category',
        text: 'Category',
        sort: true,
        headerSortingStyle,
        }, {
        dataField: 'cpusPerVm',
        text: 'vCPU',
        sort: true,
        headerSortingStyle,
        }, {
        dataField: 'memPerVm',
        text: 'RAM',
        sort: true,
        headerSortingStyle,
        }, {
        dataField: 'ntwPerf',
        text: 'Network',
        sort: true,
        headerSortingStyle,
        }, {
        dataField: 'type',
        text: 'Type'
        }, {
        dataField: 'onDemandPrice',
        text: 'Price',
        sort: true,
        headerSortingStyle
        }, {
        dataField: 'monthly',
        text: 'Monthly Price',
        sort: true,
        headerSortingStyle
        }
        
    ];

    return (
        <div>
             <BootstrapTable keyField='type' data={ data } columns={ columns } selectRow={ selectRow } headerClasses="table-header"  />
        </div>
    );
}

export default ServerList;