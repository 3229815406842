import React, {useState} from 'react';
import { Button } from 'react-bootstrap';

import FeedbackModal from './FeedbackModal';


const FeedbackButton = ({props}) => {
    const [popUpFeedback, setPopUpFeedback] = useState(false);
    const showPopUpFeedback = () => setPopUpFeedback(true);
    const hidePopUpFeedback = () => setPopUpFeedback(false);

    return (
        <div>
            <FeedbackModal showFeedback={popUpFeedback} close={hidePopUpFeedback} />
            <Button variant="outline-primary" className="Menu-Button" onClick={showPopUpFeedback}>Provide Feedback</Button>
        </div>
    );
}

export default FeedbackButton;