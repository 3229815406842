import React, {createContext} from 'react';

export const GlobalContext = createContext('');

export const Global = (props) => {
    const Server_API = 'https://cloudinfo.next2it.co.uk/api/v1/'

    return (
        <GlobalContext.Provider value={[Server_API]}>
            {props.children}
        </GlobalContext.Provider>
    );

}