import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap';
import CsvDownload from 'react-json-to-csv'
import CurrencyConverter from 'react-currency-conv';

export default function SelectedBill({selectedItems, totalPrice, yearlyTotalPrice}) {
    return (
        <div>
            <Card className="servers-card">
                <Card.Body>
                    <Card.Title><h5 className="mb-3">Billing for </h5></Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Cloud Services</Card.Subtitle>
                    <Card.Text>
                        <p>Prices are provided in Pounds</p>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                                Monthly Cost
                        <span>£{(totalPrice / 0.72).toFixed(2)}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                                <div>
                                    <strong>The total for the year</strong>
                                </div>
                                <span><strong>£{(yearlyTotalPrice / 0.72).toFixed(2)}</strong></span>
                            </li>
                        </ul>
                        <CsvDownload className="btn btn-primary btn-block" data={selectedItems} />
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    )
}
