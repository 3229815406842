import React, {useState} from 'react'
import ReactDom from 'react-dom'
import {Modal, Form, Button} from 'react-bootstrap';

export default function ScreenCheck() {
    const [popUp, setPopUp] = useState(true);

    function checkScreen() {
        var w = window,
            d = document,
            e = d.documentElement,
            g = d.getElementsByTagName('body')[0],
            windowWidth = w.innerWidth || e.clientWidth || g.clientWidth; //window width

        return windowWidth > 568; //returns true for widths larger than 568 pixels
    };

    const OVERLAY_STYLES = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, .8)',
        zIndex: 1000
    }

    if (checkScreen()) {
        return null;
    }

    return ReactDom.createPortal(
        <>
            <div style={OVERLAY_STYLES} />
            <Modal show={popUp}>
                <Modal.Header>
                    <Modal.Title>Screen Size</Modal.Title>
                </Modal.Header>
                <Modal.Body><p>Sorry this application is designed for use on a larger screen. Please try this application on a laptop, desktop or tablet.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit" href="https://www.next2it.co.uk">
                        Back to Next2IT website. 
                    </Button>
                </Modal.Footer>
            </Modal>
        </>,
        document.getElementById('portal')
    );
}

