import React, { useState, useContext } from 'react';
import { Navbar, Nav, NavDropdown, Form, Container } from 'react-bootstrap';
import HomeButton from './HomeButton';
import FeedbackButton from './FeedbackButton';
import VideoButton from './VideoButton';
import { GlobalContext } from '../Global';

const Mainmenu = () => {
    const user = localStorage.getItem('wcUser');
    const token = localStorage.getItem('wcToken');

    const Server = useContext(GlobalContext);
    const Server_API = Server + 'api/v1/'

    const [menuLoaded, setMenuLoaded] = useState(false);

    const menuItems = [
            {name: 'Calculator', link: '/Cloud/Cost_Cal'},
            {name: 'Comparison', link: '/Cloud/Cost_Com'}
    ];

    function SubMenu({ items }) {
        return (
            <>
                {items.map(({ name, link }) => (
                    <NavDropdown.Item href={link}>{name}</NavDropdown.Item>
                ))}
            </>
        )
    };

    function Menu({ items }) {
        return (
            <>
                {items.map(({ name, dropdown, link, submenu }) => (
                    <>
                        {dropdown ?
                            <>
                                <NavDropdown title={name} id="basic-nav-dropdown">
                                    <SubMenu items={submenu} />
                                </NavDropdown>
                            </>
                            :
                            <Nav.Link href={link}>{name}</Nav.Link>}
                    </>
                ))}
            </>
        )
    };




    return (
        <div>
            <Navbar expand="lg" variant="light" bg="light" className="pt-2">
                <Container>
                    <Navbar.Brand href="#home">
                        <img className="logo" src="https://i2.wp.com/www.next2it.co.uk/wp-content/uploads/2020/07/Next2IT-Logo-6.png?fit=377%2C137&ssl=1" alt="Logo"></img>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <Menu items={menuItems} />
                        </Nav>
                        <Form inline>
                            <VideoButton />
                            <FeedbackButton />
                            <HomeButton />
                        </Form>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

export default Mainmenu;