import React, {useState, createContext} from 'react';

export const ItemListContext = createContext('');

export const ItemListProvider = (props) => {
    const [selectedItems, setSelectedItems] = useState([]);

    return (
        <ItemListContext.Provider value={[selectedItems, setSelectedItems]}>
            {props.children}
        </ItemListContext.Provider>
    );

}