import React, {useState} from 'react';
import { Button } from 'react-bootstrap';

import PopUpVideo from './PopUpVideo';


const VideoButton = ({props}) => {
    const [popUpVideo, setPopUpVideo] = useState(false);
    const showPopUpVideo = () => setPopUpVideo(true);
    const hidePopUpVideo = () => setPopUpVideo(false);

    return (
        <div>
            <PopUpVideo showVideo={popUpVideo} closeVideo={hidePopUpVideo} />
            <Button variant="outline-dark" className="Menu-Button" onClick={showPopUpVideo}>How to Video</Button>
        </div>
    );
}

export default VideoButton;