import React from 'react'
import { Modal, Button } from 'react-bootstrap';

export default function PopUpVideo({ showVideo, closeVideo }) {


    return (
        <div>
            <Modal show={showVideo} centered size="lg">
                <Modal.Header>
                    <Modal.Title>How to video</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        className="video"
                        style={{
                            position: "relative",
                            paddingBottom: "56.25%" /* 16:9 */,
                            paddingTop: 25,
                            height: 0
                        }}
                    >
                        <iframe
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%"
                            }}
                            allowfullscreen="allowfullscreen"
                            mozallowfullscreen="mozallowfullscreen"
                            msallowfullscreen="msallowfullscreen"
                            oallowfullscreen="oallowfullscreen"
                            webkitallowfullscreen="webkitallowfullscreen"
                            src="https://www.youtube.com/embed/OWk4fuS1oJY"
                            frameBorder="0"
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="button" onClick={closeVideo}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
