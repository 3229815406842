import React, { useEffect, useState, useContext } from 'react';
import { Form, Container, Row, Col, Card } from 'react-bootstrap';
import { ItemListContext } from './ItemList';
import CsvDownload from 'react-json-to-csv'

import Servers from './Servers';
import CompareList from './CompareList';

import { GlobalContext } from '../Global';

import './Servers.css';

const Select = ({ props }) => {
  const Server_API = useContext(GlobalContext);
  // const Server_API = 'https://banzaicloud.com/cloudinfo/api/v1/'; //?limit=10000
  const API_Providers = Server_API + 'providers/'

  const [selectedProvider, setSelectedProvider] = useState('');
  const [selectedService, setSelectedService] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [totalPrice, setTotalPrice] = useState('0');
  const [yearlyTotalPrice, setYearlyTotalPrice] = useState('0');

  const [providers, setProviders] = useState([]);
  const [services, setServices] = useState([]);
  const [regions, setRegions] = useState([]);
  const [servers, setServers] = useState([]);

  const [loadingProviders, setLoadingProviders] = React.useState(true);
  const [loadingServices, setLoadingServices] = React.useState(true);
  const [loadingRegions, setLoadingRegions] = React.useState(true);


  const [selectedItems, setSelectedItems] = useContext(ItemListContext);


  const calcTotalPrice = () => {
    const hour = selectedItems.reduce((prev, next) => prev + next.onDemandPrice, 0);
    const total = hour * 730;
    const year = hour * 8760;
    const cleanTotal = (Math.round(total * 100) / 100).toFixed(2);
    const cleanYear = (Math.round(year * 100) / 100).toFixed(2);
    setTotalPrice(cleanTotal);
    setYearlyTotalPrice(cleanYear);
  };

  useEffect(() => {
    getProviders();
    if (selectedProvider.length > 1) {
      getServices();
    }
  }, []);

  useEffect(() => {
    if (selectedProvider != 'Select') {
      if (selectedProvider.length > 1) {
        getServices();
      }
    };
  }, [selectedProvider]);

  useEffect(() => {
    if (selectedService != 'Select') {
      if (selectedService.length > 1) {
        getRegions();
      }
    };
  }, [selectedService]);

  useEffect(() => {
    if (selectedRegion.length > 1) {
      getServers();
    }
  }, [selectedRegion]);

  useEffect(() => {
    if (selectedItems.length > 1) {
      calcTotalPrice();
    }
  }, [selectedItems]);

  const refreshPage = () => {
    window.location.reload(false);
  };


  const resetBacket = () => {
    setSelectedItems('');
    setTotalPrice('');
    setYearlyTotalPrice('');
  };

  const getProviders = async () => {
    const response = await fetch(API_Providers, {
      cache: 'reload',
    });

    const data = await response.json();
    setProviders(data.providers);

    var json = {
      "provider": "Select"
    };

    setProviders((providers) => [json, ...providers]);
    setLoadingProviders(false);

  };

  const getServices = async () => {
    const response = await fetch(API_Providers + selectedProvider + '/services', {
      cache: 'reload',
    });

    const data = await response.json();

    var json = {
      "service": "Select"
    };

    setServices(data.services);
    setServices((services) => [json, ...services]);
    setLoadingServices(false);

  };

  const getRegions = async () => {
    const response = await fetch(API_Providers + selectedProvider + '/services/' + selectedService + '/regions', {
      cache: 'reload',
    });

    const data = await response.json();
    var json = {
      "name": "Select"
    };
    setRegions(data);
    setRegions((regions) => [json, ...regions]);
    setLoadingRegions(false);
  };

  const getServers = async () => {
    const response = await fetch(API_Providers + selectedProvider + '/services/' + selectedService + '/regions/' + selectedRegion + '/products', {
      cache: 'no-cache',
    });

    const data = await response.json();
    setServers(data.products);
  };

  const changeProvider = (value) => {
    setSelectedProvider(value);
    setSelectedService('Select');
    setLoadingServices(true);
    setLoadingRegions(true);
  }


  return (
    <div>
      <Container fluid style={{ paddingTop: 5 }}>
        <Row md={2}>
          <Col xs={6} md={4}>
            <Card className="top-card">
              <Card.Body>
                <Form>
                  <Form.Group controlId="selectForm.Provider">
                    <Form.Label>Select Provider</Form.Label>
                    <Form.Control
                      as="select"
                      disabled={loadingProviders}
                      value={selectedProvider}
                      onChange={(e) => changeProvider(e.currentTarget.value)}>
                      {providers.map((provider) =>
                        <option key={provider.provider} value={provider.provider}>{provider.provider}</option>)}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="selectForm.Service">
                    <Form.Label>Select Service</Form.Label>
                    <Form.Control
                      as="select"
                      disabled={loadingServices}
                      value={selectedService}
                      onChange={(e) => setSelectedService(e.currentTarget.value)}>
                      {services.map((service) =>
                        <option key={service.service} value={service.service}>{service.service}</option>)}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="selectForm.Region">
                    <Form.Label>Select Region</Form.Label>
                    <Form.Control as="select" value={selectedRegion} disabled={loadingRegions}
                      onChange={(e) => setSelectedRegion(e.currentTarget.value)}>
                      {regions.map((region) =>
                        <option key={region.name} value={region.id}>{region.name}</option>)}
                    </Form.Control>
                  </Form.Group>
                </Form>
                <button type="button" onClick={refreshPage} className="btn btn-primary btn-block button-reset">Reset</button>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={8}>
            <Card className="top-card">
              <Card.Body>
                <Card.Title><h5>Select Server</h5></Card.Title>
                <div className="scrolling-list">
                  <Container fluid style={{ paddingTop: 0 }}>
                    <Servers data={servers} provider={selectedProvider} />
                  </Container>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid style={{ paddingTop: 5 }}>
        <CompareList data={selectedItems} provider={selectedProvider} />
      </Container>
    </div>
  );
}

export default Select;
