import React, { useContext, useState } from 'react';
import NameServer from './NameServer';

import BootstrapTable from 'react-bootstrap-table-next';
import { ItemListContext } from './ItemList';

const Server = ({ data, provider }) => {

    const [selectedItems, setSelectedItems] = useContext(ItemListContext);
    const [itemToAdd, setItemToAdd] = useState("");
    const [addServerName, setAddServerName] = useState("");
    const [addQty, setAddQty] = useState(1);
    const [popUp, setPopUp] = useState(false);
    const popUpServers = () => setPopUp(true);
    const popUpClose = () => {
        setItemToAdd("");
        setAddServerName("");
        setPopUp(false);
    }
    const [tableIndex, setTableIndex] = useState(0);

    function popUpSuccess () {
        setPopUp(false);
        var server = new item(tableIndex, addServerName, addQty, provider, itemToAdd.category, itemToAdd.type, itemToAdd.onDemandPrice);
        setSelectedItems(prevList => [...prevList, server]);
    }

    const headerSortingStyle = { backgroundColor: '#c8e6c9' };

    function item(tableIndex, addServerName, addQty, provider, category, type, onDemandPrice) {
        this.id = tableIndex;
        this.name = addServerName;
        this.qty = addQty;
        this.provider = provider;
        this.category = category;
        this.type = type;
        this.onDemandPrice = onDemandPrice;
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        hideSelectColumn: true,
        bgColor: '#00BFFF',
        onSelect: (row, isSelect, rowIndex, e) => {
            setTableIndex(tableIndex + 1);
            setItemToAdd(row);
            popUpServers();
        }
    };

    const columns = [{
        dataField: 'category',
        text: 'Category',
        sort: true,
        headerSortingStyle,
    }, {
        dataField: 'type',
        text: 'Type'
    }, {
        dataField: 'cpusPerVm',
        text: 'Virtual CPUs',
        sort: true,
        headerSortingStyle
    }, {
        dataField: 'memPerVm',
        text: 'Memory',
        sort: true,
        headerSortingStyle
    }, {
        dataField: 'gpusPerVm',
        text: 'GPUs',
        sort: true,
        headerSortingStyle
    }, {
        dataField: 'ntwPerf',
        text: 'Network Performance',
        sort: true,
        headerSortingStyle
    }, {
        dataField: 'onDemandPrice',
        text: 'Price Per Hour ($)',
        sort: true,
        formatter: (cell, row, rowIndex) => (row.onDemandPrice).toFixed(2),
        headerSortingStyle
    }

    ];

    return (
        <div>
            <NameServer popUp={popUp} save={popUpSuccess} addServer={setAddServerName} addQty={setAddQty} close={popUpClose} />
            <BootstrapTable keyField='type' data={data} columns={columns} selectRow={selectRow} />
        </div>
    );
}

export default Server;