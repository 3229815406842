import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ItemListContext } from './ItemList';

import Servers from './Servers';
import CurrentList from './CurrentList';
import SelectCard from './SelectCard';
import SelectedCard from './SelectedCard';
import SelectedBill from './SelectedBill';

import { GlobalContext } from '../Global';

import './Servers.css';

const Select = ({ props }) => {
  const Server_API = useContext(GlobalContext);

  const API_Providers = Server_API + 'providers/'

  const [selectedProvider, setSelectedProvider] = useState('');
  const [selectedService, setSelectedService] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [totalPrice, setTotalPrice] = useState('0');
  const [yearlyTotalPrice, setYearlyTotalPrice] = useState('0');

  const [providers, setProviders] = useState([]);
  const [services, setServices] = useState([]);
  const [regions, setRegions] = useState([]);
  const [servers, setServers] = useState([]);

  const [loadingProviders, setLoadingProviders] = React.useState(true);
  const [loadingServices, setLoadingServices] = React.useState(true);
  const [loadingRegions, setLoadingRegions] = React.useState(true);

  const [selectedItems, setSelectedItems] = useContext(ItemListContext);

  const [popUp, setPopUp] = useState(false);
  const popUpSuccess = () => setPopUp(false);
  const popUpServers = () => setPopUp(true);


  const calcTotalPrice = () => {
    const hour = selectedItems.reduce((prev, next) => prev + (next.onDemandPrice * next.qty), 0);
    const total = hour * 730;
    const year = hour * 8760;
    const cleanTotal = (Math.round(total * 100) / 100).toFixed(2);
    const cleanYear = (Math.round(year * 100) / 100).toFixed(2);
    setTotalPrice(cleanTotal);
    setYearlyTotalPrice(cleanYear);
  };

  useEffect(() => {
    getProviders();
    if (selectedProvider.length > 1) {
      getServices();
    }
  }, []);

  useEffect(() => {
    if (selectedProvider != 'Select') {
      if (selectedProvider.length > 1) {
        getServices();
      }
    };
  }, [selectedProvider]);

  useEffect(() => {
    if (selectedService != 'Select') {
      if (selectedService.length > 1) {
        getRegions();
      }
    };
  }, [selectedService]);

  useEffect(() => {
    if (selectedRegion.length > 1) {
      getServers();
    }
  }, [selectedRegion]);

  useEffect(() => {
    if (selectedItems.length >= 1) {
      calcTotalPrice();
    }
  }, [selectedItems]);

  const refreshPage = () => {
    window.location.reload(false);
  };

  const getProviders = async () => {
    const response = await fetch(API_Providers, {
      cache: 'reload',
    });

    const data = await response.json();
    setProviders(data.providers);

    var json = {
      "provider": "Select"
    };

    setProviders((providers) => [json, ...providers]);
    setLoadingProviders(false);

  };

  const getServices = async () => {
    const response = await fetch(API_Providers + selectedProvider + '/services', {
      cache: 'reload',
    });

    const data = await response.json();

    var json = {
      "service": "Select"
    };

    setServices(data.services);
    setServices((services) => [json, ...services]);
    setLoadingServices(false);

  };

  const getRegions = async () => {
    const response = await fetch(API_Providers + selectedProvider + '/services/' + selectedService + '/regions', {
      cache: 'reload',
    });

    const data = await response.json();
    var json = {
      "name": "Select"
    };
    setRegions(data);
    setRegions((regions) => [json, ...regions]);
    setLoadingRegions(false);
  };

  const getServers = async () => {
    const response = await fetch(API_Providers + selectedProvider + '/services/' + selectedService + '/regions/' + selectedRegion + '/products', {
      cache: 'no-cache',
    });

    const data = await response.json();
    setServers(data.products);
  };

  const changeProvider = (value) => {
    setSelectedProvider(value);
    setSelectedService('Select');
    setLoadingServices(true);
    setLoadingRegions(true);
  }


  return (
    <div>
      <Container fluid style={{ paddingTop: 5 }}>
        <Row md={3}>
          <Col>
            <SelectCard
              loadingProviders={loadingProviders}
              selectedProvider={selectedProvider}
              changeProvider={changeProvider}
              providers={providers}
              loadingServices={loadingServices}
              selectedService={selectedService}
              setSelectedService={setSelectedService}
              services={services}
              selectedRegion={selectedRegion}
              loadingRegions={loadingRegions}
              setSelectedRegion={setSelectedRegion}
              regions={regions}
            />
          </Col>
          <Col>
            <SelectedCard 
            popUpServers={popUpServers}
            refreshPage={refreshPage}
            selectedItems={selectedItems}
            />
          </Col>
          <Col>
            <SelectedBill
              selectedItems={selectedItems}
              totalPrice={totalPrice}
              yearlyTotalPrice={yearlyTotalPrice}
            />
          </Col>
        </Row>
      </Container>
      <Container fluid style={{ paddingTop: 5 }}>
        <Servers data={servers} provider={selectedProvider} />
      </Container>
      <CurrentList popUp={popUp} list={selectedItems} close={popUpSuccess} reset={refreshPage} />
    </div>
  );
}

export default Select;
