import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './fonts/Rubik.ttf';

import ScreenCheck from './core/ScreenCheck';
import PopUpEmail from './core/PopUpEmail';
import PopUpNew from './core/PopUpNew';

import TopBar from './core/TopBar';
import Mainmenu from './core/MainMenu';

import CloudVMPricing from './cloudcalc/CloudVMPricing';
import CloudComp from './cloudcom/CloudComp';

import { Global } from './Global';

import './App.css';

const App = () => {
  return (
    <div className="app">
      <ScreenCheck />
      <PopUpEmail />
      <PopUpNew />
      <TopBar />
      <Global>
        <Mainmenu />
        <Router>
          <Switch>
            <Route exact path="/" component={CloudVMPricing} />
            <Route exact path="/Home" component={CloudVMPricing} />
            <Route exact path="/Cloud/Cost_Cal" component={CloudVMPricing} />
            <Route exact path="/Cloud/Cost_Com" component={CloudComp} />
          </Switch>
        </Router>
      </Global>
    </div>
  );
}


export default App;
