import React from 'react';
import {Modal, Container, Button} from 'react-bootstrap';
import ServersList from './ServersList';

function CurrentList({popUp, list, close, reset}) {
    return (
        <div>
            <Modal show={popUp} size="lg">
                <Modal.Header>
                    <Modal.Title>Selected Servers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="scrolling-list">
                  <Container fluid style={{ paddingTop: 0 }}>
                    <ServersList data={list}/>
                  </Container>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="button" onClick={reset}>
                        Reset Server
                    </Button>
                    <Button variant="primary" type="button" onClick={() => close()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CurrentList
