import React from 'react';
import IosChatboxesOutline from 'react-ionicons/lib/IosChatboxesOutline';
import IosContact from 'react-ionicons/lib/IosContact';
import IosConstructOutline from 'react-ionicons/lib/IosConstructOutline';
import IosHelpCircleOutline from 'react-ionicons/lib/IosHelpCircleOutline';
import IosPaperOutline from 'react-ionicons/lib/IosPaperOutline';
import IosMailOutline from 'react-ionicons/lib/IosMailOutline';

const TopBar = () => {
    return (

        <div>
            <div id="site-topbar" className="site-topbar">
            <div className="wrap">
            <div className="site-topbar-inner">
            <div className="topbar-text">
            <ul className="list-info">
            <li>
            <IosChatboxesOutline fontSize="48px" color="rgba(255, 251, 251, 0.5)"/>
            <a className="content" href="tel:+443301332202">
            <span className="text">Contact</span>
            <span className="action">0330 1332 202</span>
            </a>
            </li>
            <li>
            <IosContact fontSize="48px" color="rgba(255, 251, 251, 0.5)"/>
            <a className="content" href="mailto:support@next2it.co.uk">
            <span className="text">Have a question?</span>
            <span className="action">support@next2it.co.uk</span>
            </a>
            </li>
            </ul> </div>

            <div className="topbar-menu">
            <ul id="menu-top-menu" className="menu menu-top"><li id="menu-item-1800" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1800">
                <a href="https://www.next2it.co.uk/" data-ps2id-api="true"><IosConstructOutline fontSize="32px" color="White"/><span className="content">Our Services</span></a></li>
            <li id="menu-item-1801" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1801">
                <a href="https://www.next2it.co.uk/faq" data-ps2id-api="true"><IosHelpCircleOutline fontSize="32px" color="White"/><span className="content">FAQ</span></a></li>
            <li id="menu-item-1804" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1804">
                <a href="https://www.next2it.co.uk/home/it-blog/" data-ps2id-api="true"><IosPaperOutline fontSize="32px" color="White"/><span className="content">Blog</span></a></li>
            <li id="menu-item-1756" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1756">
                <a href="https://www.next2it.co.uk/home/contact-us/" data-ps2id-api="true"><IosMailOutline fontSize="32px" color="White"/><span className="content">Contact Us</span></a></li>
            </ul> 
            </div>
            </div>
            </div>
            </div>
        </div>
    );
}


export default TopBar;