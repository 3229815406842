import React, {useState, useEffect} from 'react';
import PopUpVideo from './PopUpVideo';

export default function PopUpNew(props) {
    const startup = localStorage.getItem('startup');

    const [popUpVideo, setPopUpVideo] = useState(false);
    const showPopUpVideo = () => setPopUpVideo(true);
    const hidePopUpVideo = () => { 
        localStorage.setItem('startup', 'true');
        window.location.reload(false);
    }

    useEffect(() => {
        if (!startup) {
            setTimeout(() => {
                setPopUpVideo(true);
            }, 5000);
        }
    });
    return (
        <div>
            <PopUpVideo showVideo={popUpVideo} closeVideo={hidePopUpVideo} />
        </div>
    )
}
