import React from 'react'
import { Form, Container, Row, Col, Card } from 'react-bootstrap';

export default function SelectCard({loadingProviders, selectedProvider, changeProvider, providers, loadingServices, selectedService, setSelectedService, services, selectedRegion, loadingRegions, setSelectedRegion, regions}) {
    return (
        <div>
            <Card className="servers-card">
                <Card.Body>
                    <Form>
                        <Form.Group controlId="selectForm.Provider">
                            <Form.Label>Select Provider</Form.Label>
                            <Form.Control
                                as="select"
                                disabled={loadingProviders}
                                value={selectedProvider}
                                onChange={(e) => changeProvider(e.currentTarget.value)}>
                                {providers.map((provider) =>
                                    <option key={provider.provider} value={provider.provider}>{provider.provider}</option>)}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="selectForm.Service">
                            <Form.Label>Select Service</Form.Label>
                            <Form.Control
                                as="select"
                                disabled={loadingServices}
                                value={selectedService}
                                onChange={(e) => setSelectedService(e.currentTarget.value)}>
                                {services.map((service) =>
                                    <option key={service.service} value={service.service}>{service.service}</option>)}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="selectForm.Region">
                            <Form.Label>Select Region</Form.Label>
                            <Form.Control as="select" value={selectedRegion} disabled={loadingRegions}
                                onChange={(e) => setSelectedRegion(e.currentTarget.value)}>
                                {regions.map((region) =>
                                    <option key={region.name} value={region.id}>{region.name}</option>)}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    )
}
