import React from 'react';

import SelectServers from './SelectServers';
import {ItemListProvider} from './ItemList';



const CloudVMPricing = () => {

    return (
        <div>
            <ItemListProvider>
                <SelectServers />
            </ItemListProvider>
        </div>
    );
}

export default CloudVMPricing;