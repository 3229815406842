import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import { ItemListContext } from './ItemList';

const ServerList = ({ data, popName }) => {

    const [selectedItems, setSelectedItems] = useContext(ItemListContext);

    const headerSortingStyle = { backgroundColor: '#c8e6c9' };

    const deleteCell = (index) => {
        console.log(index);
        setSelectedItems(selectedItems.filter(item => item.id != index));
    };

    const columns = [
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
            headerSortingStyle,
        }, {
            dataField: 'qty',
            text: 'Quantity',
            sort: true,
            headerSortingStyle,
        }, {
            dataField: 'provider',
            text: 'Provider',
            sort: true,
            headerSortingStyle,
        }, {
            dataField: 'category',
            text: 'Category',
            sort: true,
            headerSortingStyle,
        }, {
            dataField: 'type',
            text: 'Type'
        }, {
            dataField: 'onDemandPrice',
            text: 'Cost per hour',
            sort: true,
            formatter: (cell, row, rowIndex) => (row.onDemandPrice).toFixed(2),
            headerSortingStyle
        }, {
            text: 'Total Cost per hour',
            sort: true,
            headerSortingStyle,
            formatter: (cell, row, rowIndex) => (row.qty * row.onDemandPrice).toFixed(2)
        },  {
            formatter: (cell, row, rowIndex) => <Button onClick={() => deleteCell(row.id)}>Delete</Button>
        }

    ];

    return (
        <div>
            <BootstrapTable keyField='type' data={data} columns={columns}/>
        </div>
    );
}

export default ServerList;