import React from 'react';
import { Button } from 'react-bootstrap';


const HomeButton = (props) => {

    return (
        <div>
            <Button variant="outline-danger" href="https://www.next2it.co.uk" className="Menu-Button">Back to our site</Button>
        </div>
    );
}

export default HomeButton;