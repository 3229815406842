import React,{useContext} from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import {ItemListContext} from './ItemList';


const Server = ({data, provider}) => {

    const [selectedItems, setSelectedItems] = useContext(ItemListContext);

    const headerSortingStyle = { backgroundColor: '#c8e6c9' };

    function item (provider, category, type, onDemandPrice, cpusPerVm, gpusPerVm, memPerVm, ntwPerf) {
            this.provider = provider;
            this.category = category;
            this.type = type;
            this.onDemandPrice = ('$ ' + onDemandPrice.toFixed(2));
            this.cpusPerVm = cpusPerVm;
            this.gpusPerVm = gpusPerVm;
            this.memPerVm = memPerVm;
            this.ntwPerf = ntwPerf;
            this.monthly = ('$' + (onDemandPrice * 730).toFixed(2));
    }

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        hideSelectColumn: true,
        bgColor: 'orange',
        onSelect:(row, isSelect, rowIndex, e) => {
            var itemToAdd = new item(provider, row.category, row.type, row.onDemandPrice, row.cpusPerVm, row.gpusPerVm, row.memPerVm, row.ntwPerf);
            setSelectedItems(prevList => [...prevList, itemToAdd] );
        }
    };

    const columns = [{
        dataField: 'category',
        text: 'Category',
        sort: true,
        headerSortingStyle,
        }, {
        dataField: 'type',
        text: 'Type',
        sort: true,
        headerSortingStyle,
        }, {
        dataField: 'cpusPerVm',
        text: 'vCPUs',
        sort: true,
        headerSortingStyle 
        }, {
        dataField: 'memPerVm',
        text: 'RAM',
        sort: true,
        headerSortingStyle
        }, {
        dataField: 'ntwPerf',
        text: 'Network',
        sort: true,
        headerSortingStyle
        }, {
        dataField: 'onDemandPrice',
        text: 'Price Per Hour',
        sort: true,
        formatter: (cell, row, rowIndex) => (row.onDemandPrice).toFixed(2),
        headerSortingStyle
        }
        
    ];

    return (
        <div>
             <BootstrapTable keyField='type' data={data} columns={ columns } selectRow={ selectRow } headerClasses="table-header" />
        </div>
    );
}

export default Server;