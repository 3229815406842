import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from "react-bootstrap";
import ReactDom from 'react-dom'
import firebase from "./Firebase";
import 'firebase/firestore';

export default function PopUpEmail({ props }) {

    const access = localStorage.getItem('access');
    const publicIp = require('public-ip');

    const [popUp, setPopUp] = useState(false);
    const popUpSuccess = () => setPopUp(false);

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");

    useEffect(() => {
        setTimeout(() => {
            setPopUp(true);
        }, 120000);
    });

    const OVERLAY_STYLES = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, .8)',
        zIndex: 1000
    }

    function Complete() {
        localStorage.setItem('access', 'true');
        localStorage.setItem('email', email);
        localStorage.setItem('name', name);
        popUpSuccess();
    };

    const addUser = async (e) => {
        e.preventDefault();
        const db = firebase.firestore();
        db.settings({
            timestampsInSnapshots: true
        });
        const userRef = db.collection('customers').add({
            name: name,
            email: email,
            time: firebase.firestore.Timestamp.now(),
            ip: await publicIp.v4()
        });
        window.FS.identify(email, {
            displayName: name,
            email: email
          });
        Complete();
    };

    function BackGround(props) {
        if (popUp) {
            return <div style={OVERLAY_STYLES} />
        }
        return null
    };


    function validateForm() {
        return email.length > 0 && name.length > 0;
    };

    if (access) {
        return null;
    }
    return ReactDom.createPortal(
        <>
            <BackGround />
            <Modal show={popUp} closeButton>
                <Modal.Header>
                    <Modal.Title>Your Details</Modal.Title>
                </Modal.Header>
                <Modal.Body><p>To continue using the Next2IT price calculator please provide your details.</p>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} />
                        <Form.Text className="text-muted">
                            We'll never share your email with anyone else.
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formBasicName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Name" value={name} onChange={e => setName(e.target.value)} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit" disabled={!validateForm()} onClick={addUser}>
                        Submit
                    </Button>
                    <Button variant="secondary" onClick={popUpSuccess}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>,
        document.getElementById('portal')
    );
}
