import React, {useState} from 'react'
import { Modal, Form, Button } from 'react-bootstrap';
import firebase from "./Firebase";
import 'firebase/firestore';

export default function FeedbackModal({ showFeedback, close }) {
    const publicIp = require('public-ip');

    const name = localStorage.getItem('name');
    const email = localStorage.getItem('email');
    const [feedback, setFeedback] = useState('');

    const saveFeedback = async (e) => {
        e.preventDefault();
        const db = firebase.firestore();
        const sfeedback = db.collection('feedback').add({
            name: name,
            email: email,
            feedback: feedback,
            time: firebase.firestore.Timestamp.now(),
            ip: await publicIp.v4()
        });
        setFeedback('');
        close();
    };

    function validateForm() {
        return feedback.length > 1 ;
    };

    return (
        <div>
            <Modal show={showFeedback} centered>
                <Modal.Header>
                    <Modal.Title>Provide Feedback</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="formBasicName">
                        <Form.Label>Please enter your feedback.</Form.Label>
                        <Form.Control as="textarea" placeholder="Feedback" value={feedback} onChange={e => setFeedback(e.target.value)} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="button" onClick={saveFeedback} disabled={!validateForm()}>
                        Submit
                    </Button>
                    <Button variant="secondary" type="button" onClick={close}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
