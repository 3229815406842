import { firebase } from '@firebase/app';
import '@firebase/firestore'

const config = {
    apiKey: "AIzaSyAPdm05vU146Ys3Ze8Pp4PGx0m7WJAPLrA",
    authDomain: "next2it-cc.firebaseapp.com",
    databaseURL: "https://next2it-cc-default-rtdb.firebaseio.com",
    projectId: "next2it-cc",
    storageBucket: "next2it-cc.appspot.com",
    messagingSenderId: "901422460543",
    appId: "1:901422460543:web:a01951b3c5bd5d452e194a",
    measurementId: "G-0TDVCKRLQ1"
};

firebase.initializeApp(config);

export default firebase;