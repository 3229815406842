import React from 'react'
import { Modal, Button, Form } from 'react-bootstrap';

export default function NameServer({ popUp, close, addServer, addQty, save }) {
    return (
        <div>
            <Modal show={popUp}>
                <Modal.Header>
                    <Modal.Title>Add Server</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="formBasicName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Server Name" onChange={e => addServer(e.target.value)} />
                    </Form.Group>
                    <Form.Group controlId="formBasicQuantity">
                        <Form.Label>How Many Instances?</Form.Label>
                        <Form.Control type="number" placeholder="Quantity" onChange={e => addQty(e.target.value)} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="button" onClick={save}>
                        Add Server
                    </Button>
                    <Button variant="primary" type="button" onClick={close}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
