import React from 'react'
import { Card, Button } from 'react-bootstrap';


export default function SelectedCard({ selectedItems, popUpServers, refreshPage }) {
    return (
        <div>
            <Card className="servers-card">
                <Card.Body>
                    <Card.Title><h5>Selected</h5></Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Cloud Servers</Card.Subtitle>
                    <Card.Text>
                    <ul className="list-group list-group-flush">
                            <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                                Number Of Servers
                    <span>{selectedItems.length}</span>
                            </li>
                        </ul>
                        <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3"></li>
                        <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3"></li>
                        <Button type="button" onClick={popUpServers} className="btn-block">Selected Servers</Button>
                        <Button type="button" onClick={refreshPage} className="btn-block">Reset Server</Button>
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    )
}
